
import { computed, defineComponent, getCurrentInstance, ref, onBeforeMount } from "vue";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { useRoute, useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import { notif } from "@/store/stateless/store";

export default defineComponent({
  name: "policy-transfer-modal",
  components: { Multiselect },
  props: {
    policyId: Number,
  },
  setup(_, context) {
    const store = useStore();
    const transferPolicyModalRef = ref<null | HTMLElement>(null);
    let underWriter = computed(() => {
      return store.getters.getUnderWriters;
    });
    let underWriterList = ref({
      value: null,
      options: underWriter,
      placeholder: "Please Select Underwritter",
      searchable: true,
      createTag: true,
    });

    const isValid = ref(true);

    const transferPolicy = (policy_id) => {
      if(!underWriterList.value.value) {
        isValid.value = false;
      } else {
          PolicyService.transferUnderwriter({
            policy_id: policy_id,
            policy_creator_id: underWriterList.value.value,
          }).then((data) => {
            hideModal(transferPolicyModalRef.value);
            underWriterList.value.value = null;
            context.emit('reset_policy_data');
            notif.simple("Policy Transfer", "success", "You have successfully change the underwriter");
        });
      }
    };
    const disableTransferSale = computed(() => {
      return store.getters.getDisableTransferSale;
    });

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        const transferRef:any = inst?.refs?.transferRef
        transferRef.focusSearch()
      }, 500);
    }

    const closeTime = () => {
          cancelButton.value.click()
        underWriterList.value.value = null;
    }

    
    const cancelButton =ref()
    const closeModal = (e) => {
      if(e.which === 27) {
        closeTime()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      closeTime,
      cancelButton,
      toFirst,
      isValid,
      underWriterList,
      transferPolicy,
      disableTransferSale,
      transferPolicyModalRef,
    };
  },
});
