
import { computed, defineComponent, getCurrentInstance, onBeforeMount, onMounted, ref, watchEffect } from "vue";

import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import DropzoneFile from "@/views/car/policy/DropZone.vue";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
import { Mutations } from "@/store/enums/StoreEnums";
import MasterService from "@/core/services/car/MasterService";
import { notif } from "@/store/stateless/store";

interface editCancelPolicyBodyParam {
  policy_id: any | null;
  cancellation_type: any | null;
  cancellation_payable_amount: any | null;
  cancellation_credit_amount: any | null;
  cancellation_credit_notes_no: any | null;
  cancellation_debit_amount: any | null;
  cancellation_debit_notes_no: any | null;
  cancellation_endorsement_no: any | null;
  underwriter_cancellation_notes: any | null;
  cancellation_date: any | null;
  documents: any | null;
}
interface PolicyBodyParam {
  per_page: string;
  status: any;
  keyword: string;
  start_date: string | null;
  end_date: string | null;
  agent: string;
  policy_sales_agent: any;
  underwriter: any;
  payment_type: any;
}
export default defineComponent({
  name: "edit-cancel-request-document-modal",
  components: {
    DropzoneFile,
    ViewTempDocuments,
  },
  props: {
    policyId: Number,
    policyObj: { type: Object, required: true },
    cancellationDocs: Array,
    status: Number,
    tempDocs: Array,
  },
  setup(props) {
    const store = useStore();
    const editCancelPolicyReqModalRef = ref<null | HTMLElement>(null);
    const inst = getCurrentInstance()

    let isFormValid = ref<boolean>(true);
    let cancellationDocuments = ref<any>();
    let editCancelPolicyBodyParam = ref<editCancelPolicyBodyParam>({
      policy_id: props.policyObj?.id,
      cancellation_type: props.policyObj?.cancellation_type,
      cancellation_payable_amount: 0,
      cancellation_debit_amount: 0,
      cancellation_debit_notes_no: '',
      cancellation_credit_amount: 0,
      cancellation_credit_notes_no: '',
      cancellation_endorsement_no: '',
      underwriter_cancellation_notes: "",
      cancellation_date: "",
      documents: [],
    });
    let bodyParameters = ref<PolicyBodyParam>({
      status: props.status,
      per_page: "50",
      keyword: "",
      start_date: "",
      end_date: "",
      agent: "all",
      policy_sales_agent: "",
      underwriter: "",
      payment_type: "",
    });
    let policyDetails = computed(() => {
      return store.getters.getpolicyDetails;
    });

    onMounted(() => {
      // MasterService.getCancellationReason();
    })

    const getPolicyList = (bodyParameters) => {
      // bodyParameters.status =
      //   bodyParameters.status == 0 ? "" : bodyParameters.status;

  bodyParameters.value.reload = false
      Promise.all([
        PolicyService.getPolicyList(bodyParameters.value),
        PolicyService.getPolicyListCount(bodyParameters.value),
      ]).then((data) => {
        // policyCount.value = store.getters.getPolicyCount;
      });
    };
    const editCancellationRules = ref({
      cancellation_payable_amount: {
        required: true,
        message: "Payable amount",
        show: false,
      },
      cancellation_credit_amount: {
        required: true,
        message: "Credit note",
        show: false,
      },
      cancellation_credit_notes_no: {
        required: true,
        message: "Credit note no.",
        show: false,
      },
      cancellation_debit_amount: {
        required: true,
        message: "Debit note",
        show: false,
      },
      cancellation_debit_notes_no: {
        required: true,
        message: "Debit note no.",
        show: false,
      },
      cancellation_endorsement_no: {
        required: true,
        message: "Endorsement no.",
        show: false,
      },
      underwriter_cancellation_notes: {
        required: true,
        message: "Please enter underwriter notes",
        show: false,
      },
      documents: {
        required: true,
        message: "Please upload cancellation document - one credit note, debit note, endorsement certificate and either New owner's mulkiya, Reverse of insurance copy from RTA, New insurance policy under new owner details",
        show: false,
        selectedDocument: false
      },
      cancellation_date: {
        message: "Please enter the cancellation date on invoice",
        show: false,
      },
      cancellation_debit_amount_error: {
        required: true,
        message: "Incorrect Amount",
        show: false,
      },
    });
    const debitDecimalValid = ref(true);
    const creditDecimalValid = ref(true);
    const validateCancellationForm = () => {
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;

      if (editCancelPolicyBodyParam.value.cancellation_payable_amount <= 0  &&
        editCancelPolicyBodyParam.value.cancellation_type != "no-reimbursement") {
        editCancellationRules.value.cancellation_payable_amount.show = true;
        isFormValid.value = false;
      }
      // if (
      //   policyDetails.value.cancellation_type == "full-cancellation" &&
      //   editCancelPolicyBodyParam.value.cancellation_payable_amount !=
      //     policyDetails.value.policy_price_amount
      // ) {
      //   editCancellationRules.value.cancellation_payable_amount.show = true;
      //   editCancellationRules.value.cancellation_payable_amount.message =
      //     "Policy Price is not equal to Payable Amount";
      //   isFormValid.value = false;
      // }
      if(editCancelPolicyBodyParam.value.cancellation_payable_amount > policyDetails.value.car_lead_invoice.sale_price) {
        editCancellationRules.value.cancellation_payable_amount.show = true;
        editCancellationRules.value.cancellation_payable_amount.message =
          "Payable amount cannot be greater than invoice amount.";
        isFormValid.value = false;
      }
      if (
        editCancelPolicyBodyParam.value.cancellation_credit_amount === "" ||
        editCancelPolicyBodyParam.value.cancellation_credit_amount === null
      ) {

        editCancellationRules.value.cancellation_credit_amount.show = true;
        isFormValid.value = false;
      }
      // if(policyDetails.value.cancellation_type !== 'no-reimbursement') {
      //   if (!editCancelPolicyBodyParam.value.cancellation_credit_notes_no) {
      //     editCancellationRules.value.cancellation_credit_notes_no.show = true;
      //     isFormValid.value = false;
      //   }
      //   if (!editCancelPolicyBodyParam.value.cancellation_debit_notes_no) {
      //     editCancellationRules.value.cancellation_debit_notes_no.show = true;
      //     isFormValid.value = false;
      //   }
      // }
      // if (!editCancelPolicyBodyParam.value.cancellation_endorsement_no) {
      //   editCancellationRules.value.cancellation_endorsement_no.show = true;
      //   isFormValid.value = false;
      // }
      //  if(!decimalPlaces.test(String(editCancelPolicyBodyParam.value.cancellation_credit_amount))) {
      //   editCancellationRules.value.cancellation_credit_amount.message = 'Please enter upto 2 decimal places only';
      //   editCancellationRules.value.cancellation_credit_amount.show = true;
      //   isFormValid.value = false;
      //   creditDecimalValid.value = false
      // }
       if(!decimalPlaces.test(String(editCancelPolicyBodyParam.value.cancellation_debit_amount))) {
        editCancellationRules.value.cancellation_debit_amount.message = 'Please enter upto 2 decimal places only';
        editCancellationRules.value.cancellation_debit_amount.show = true;
        isFormValid.value = false;
        debitDecimalValid.value = false
      }

      if(editCancelPolicyBodyParam.value.cancellation_debit_amount >= 1000000000) {
        editCancellationRules.value.cancellation_debit_amount_error.show = true;
        isFormValid.value = false;
        debitDecimalValid.value = false
      }



      if (!editCancelPolicyBodyParam.value.underwriter_cancellation_notes) {
        editCancellationRules.value.underwriter_cancellation_notes.show = true;
        isFormValid.value = false;
      }
      if (
        (editCancelPolicyBodyParam.value.cancellation_debit_amount === "" ||
          editCancelPolicyBodyParam.value.cancellation_debit_amount === null)
      ) {
        editCancellationRules.value.cancellation_debit_amount.show = true;
        isFormValid.value = false;
      }

      // hide for lebanon
      // if (editCancelPolicyBodyParam.value.documents.length == 0) {
      //   editCancellationRules.value.documents.show = true;
      //   isFormValid.value = false;
      // }

      if (!editCancelPolicyBodyParam.value.cancellation_date) {
        editCancellationRules.value.cancellation_date.show = true;
        isFormValid.value = false;
      }

      let validDocumentUploaded = ref<boolean>(false);
      let validCreditNote = ref<boolean>(false);
      let validDebitNote = ref<boolean>(false);
      let validMukiyaRTAPolicy = ref<boolean>(false);
      let endorsementCertificate = ref<boolean>(false);
      editCancelPolicyBodyParam.value.documents.forEach((doc) => {
        // if (doc.type >= 12 && doc.type <= 16) {
        //   validDocumentUploaded.value = true;
        // }
        if(doc.type == 19) {
          endorsementCertificate.value = true
          // console.log('credit')
        }
        if(doc.type == 18) {
          validCreditNote.value = true
          // console.log('credit')
        }
        if(doc.type == 17) {
          validDebitNote.value = true
          // console.log('debit')

        }

        // console.log('doc.type',doc.type)
        if(doc.type == 14 || doc.type == 15 || doc.type == 16) {
          validMukiyaRTAPolicy.value = true
          // console.log('others')

        }
      });
      // if (!validCreditNote.value || !endorsementCertificate.value || !validDebitNote.value || !validMukiyaRTAPolicy.value) {
      //   editCancellationRules.value.documents.selectedDocument = true;
      //   isFormValid.value = false;
      // }
      // console.log(editCancelPolicyBodyParam.value);
    };

    watchEffect(() => {
      
      // editCancelPolicyBodyParam.value.cancellation_payable_amount = parseFloat(editCancelPolicyBodyParam.value.cancellation_credit_amount ? editCancelPolicyBodyParam.value.cancellation_credit_amount : 0) -
      // parseFloat(editCancelPolicyBodyParam.value.cancellation_debit_amount ? editCancelPolicyBodyParam.value.cancellation_debit_amount : 0);
      
      // if(editCancelPolicyBodyParam.value.cancellation_credit_amount != editCancelPolicyBodyParam.value.cancellation_payable_amount) {
      //   roundUp(editCancelPolicyBodyParam.value.cancellation_payable_amount);
      // }
    })

    const payableAmount = () => {
      if(policyDetails.value.cancellation_type === 'partial-cancellation') {
        editCancelPolicyBodyParam.value.cancellation_payable_amount = roundUp(editCancelPolicyBodyParam.value.cancellation_credit_amount - editCancelPolicyBodyParam.value.cancellation_debit_amount);
      } else {
        editCancelPolicyBodyParam.value.cancellation_payable_amount = roundUp(editCancelPolicyBodyParam.value.cancellation_credit_amount)
      }
    }

    const calculatePayable = () => {
      editCancellationRules.value.cancellation_payable_amount.show = false;
      // editCancelPolicyBodyParam.value.cancellation_payable_amount =
      //   editCancelPolicyBodyParam.value.cancellation_credit_amount -
      //   editCancelPolicyBodyParam.value.cancellation_debit_amount;
    };
    const cancellationReason = computed(() => {
      return store.getters.getCancellationReasons;
    });

    const cancelPolicyRequest = () => {
      // console.log(props);
      resetValidation();

      isFormValid.value = true;
      editCancelPolicyBodyParam.value.documents = [];
      editCancelPolicyBodyParam.value.policy_id = props.policyId;
      editCancelPolicyBodyParam.value.cancellation_type =
        policyDetails.value.cancellation_type;

      // cancellationDocuments.value = store.getters.getTempPolicyDocs;
      cancellationDocuments.value = store.getters.getTempPolicyCancelDocs;

      cancellationDocuments.value.forEach((doc) => {
        let document = {
          document_id: doc.id,
          type: doc.type,
        };
        editCancelPolicyBodyParam.value.documents.push(document);
      });
      validateCancellationForm();
      if (isFormValid.value) {
        Promise.all([
          PolicyService.underwritterCancelPolicyRequest(
            editCancelPolicyBodyParam.value
          ),
        ]).then((data) => {
          // hideModal(editCancelPolicyReqModalRef.value);
          // editCancelPolicyBodyParam.value.cancellation_credit_amount = 0;
          // editCancelPolicyBodyParam.value.underwriter_cancellation_notes = '';
          // policyDetails.value.cancellation_type = null;
            notif.simple("Policy Cancellation", "success", "You have successfully edited the cancelled policy!");
          getPolicyList(bodyParameters);
          closeModal();
          // resetValidation();
        });
      } else {
        focusField()
      }
    };
    const showDomLoad = computed(() => {
      return store.getters.getPolicyDocumentDomStatus;
    });
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const resetValidation = () => {
      editCancellationRules.value.cancellation_payable_amount.show = false;
      editCancellationRules.value.cancellation_credit_amount.show = false;
      editCancellationRules.value.cancellation_debit_amount.show = false;
      editCancellationRules.value.underwriter_cancellation_notes.show = false;
      editCancellationRules.value.cancellation_date.show = false;
      editCancellationRules.value.documents.show = false;
      creditDecimalValid.value = true
      debitDecimalValid.value = true
    };

    const defaultValue = ref<number>(0);
    const cancellationType = (e) => {
      // if(e == 'full') {
      //   defaultValue.value = editCancelPolicyBodyParam.value.cancellation_debit_amount;
        // editCancelPolicyBodyParam.value.cancellation_debit_amount = 0
      // } else {
      //   editCancelPolicyBodyParam.value.cancellation_debit_amount = defaultValue.value
      // }
      payableAmount();
    }

    const loading = computed(() => {
      return store.getters.getEditCancellationLoading
    })

    onBeforeRouteLeave(() => {
      store.commit(Mutations.SET_EDIT_CANCELLATION_LOADING, true);
    })

    const resetFields = () => {
      editCancelPolicyBodyParam.value.cancellation_payable_amount = 0
        editCancelPolicyBodyParam.value.cancellation_debit_amount = 0
        editCancelPolicyBodyParam.value.cancellation_debit_notes_no = ''
        editCancelPolicyBodyParam.value.cancellation_credit_amount = 0
        editCancelPolicyBodyParam.value.cancellation_credit_notes_no = ''
        editCancelPolicyBodyParam.value.cancellation_endorsement_no = ''
        editCancelPolicyBodyParam.value.cancellation_date = ''
        editCancelPolicyBodyParam.value.underwriter_cancellation_notes = ''
    }

    const closeModal = () => {
      editCancelPolicyBodyParam.value.cancellation_payable_amount = 0
        editCancelPolicyBodyParam.value.cancellation_debit_amount = 0
        editCancelPolicyBodyParam.value.cancellation_debit_notes_no = ''
        editCancelPolicyBodyParam.value.cancellation_credit_amount = 0
        editCancelPolicyBodyParam.value.cancellation_credit_notes_no = ''
        editCancelPolicyBodyParam.value.cancellation_endorsement_no = ''
        editCancelPolicyBodyParam.value.cancellation_date = ''
        editCancelPolicyBodyParam.value.underwriter_cancellation_notes = ''
      hideModal(editCancelPolicyReqModalRef.value);
      resetValidation();
      resetFields();
      store.commit(Mutations.SET_EDIT_CANCELLATION_LOADING, true);
    }

    const closeTime = (e) => {
      if(e.which === 27) {
        closeModal()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closeTime);
    })

    const getTextWidth = (text, font) => {
      const canvas = document.createElement('canvas');
      const context: any = canvas.getContext('2d');

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    }

    const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const focusField = () => {
      const { cancellation_credit_amount, cancellation_credit_notes_no, cancellation_debit_amount, cancellation_debit_notes_no, cancellation_endorsement_no, underwriter_cancellation_notes, cancellation_payable_amount } = editCancellationRules.value

      if(cancellation_credit_amount.show || cancellation_payable_amount.show) {
        const cnRef:any = inst?.refs?.cnRef
        cnRef.isFocus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && cancellation_credit_notes_no.show) {
        const cnNoRef:any = inst?.refs?.cnNoRef
        cnNoRef.focus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && cancellation_debit_amount.show) {
        const dnRef:any = inst?.refs?.dnRef
        dnRef.isFocus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && !cancellation_debit_amount.show && cancellation_debit_notes_no.show) {
        const dnNoRef:any = inst?.refs?.dnNoRef
        dnNoRef.focus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && !cancellation_debit_amount.show && !cancellation_debit_notes_no.show && cancellation_endorsement_no.show) {
        const endorsementRef:any = inst?.refs?.endorsementRef
        endorsementRef.focus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && !cancellation_debit_amount.show && !cancellation_debit_notes_no.show && !cancellation_endorsement_no.show && underwriter_cancellation_notes.show) {
        const notesRef:any = inst?.refs?.notesRef
        notesRef.focus()
      }
    }

    const setFocuToPayTo = () => {
      const notesRef:any = inst?.refs?.notesRef
      notesRef.focus()
    }

    const toFirst = () => {
      setTimeout(() => {
        const cancellationTypeRef:any = inst?.refs?.cancellationTypeRef
        cancellationTypeRef.focus()
      }, 500);
    }

    const setData = () => {
      // if(Object.keys(policyDetails.value).length > 0) {
        editCancelPolicyBodyParam.value.cancellation_payable_amount = parseFloat(policyDetails.value.cancellation_payable_amount)
        editCancelPolicyBodyParam.value.cancellation_debit_amount = parseFloat(policyDetails.value.cancellation_debit_amount)
        editCancelPolicyBodyParam.value.cancellation_debit_notes_no = policyDetails.value.cancellation_debit_notes_no
        editCancelPolicyBodyParam.value.cancellation_credit_amount = parseFloat(policyDetails.value.cancellation_credit_amount)
        editCancelPolicyBodyParam.value.cancellation_credit_notes_no = policyDetails.value.cancellation_credit_notes_no
        editCancelPolicyBodyParam.value.cancellation_endorsement_no = policyDetails.value.cancellation_endorsement_no
        editCancelPolicyBodyParam.value.cancellation_date = policyDetails.value.cancellation_date
        editCancelPolicyBodyParam.value.underwriter_cancellation_notes = policyDetails.value.underwriter_cancellation_notes
      // }
    }



    
    return {
      setFocuToPayTo,
      setData,
      toFirst,
      focusField,
      payableAmount,
      roundUp,
      getTextWidth,
      creditDecimalValid,
      debitDecimalValid,
      closeModal,
      loading,
      defaultValue,
      cancellationType,
      userPermissions,
      editCancelPolicyReqModalRef,
      policyDetails,
      editCancelPolicyBodyParam,
      cancellationReason,
      calculatePayable,
      cancelPolicyRequest,
      showDomLoad,
      editCancellationRules,
      disableSaveButton,
    };
  },
});
