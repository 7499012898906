import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListPolicy = _resolveComponent("ListPolicy")!

  return (_openBlock(), _createBlock(_component_ListPolicy, {
    status: _ctx.status,
    type: _ctx.type,
    isqca: _ctx.isqca
  }, null, 8, ["status", "type", "isqca"]))
}