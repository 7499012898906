
import { computed, defineComponent } from "vue";

import { useStore } from "vuex";

import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
// import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";

export default defineComponent({
  name: "policy-details-modal",
  components: {
    PolicyDocuments,
    // ViewTempDocuments
  },

  setup() {
    const store = useStore();
    const policyDetails = computed(() => {
        return store.getters.getpolicyDetails
    })

    const policyDocuments = computed(() => {
        return store.getters.getDataViewAllDocuments
    })

    const tempDocuments = computed(() => {
      return store.getters.getTempPolicyDocs;
    });

    const loading = computed(() => {
      return store.getters.getLoadingViewAllDocuments;
    })

    const extensionsLogic = computed(() => {
          const data =  store.getters.getExtensions
          return data.length > 0 ? true : false
      })

    return { 
        extensionsLogic,
        policyDetails,
        policyDocuments,
        tempDocuments,
        loading,
     };
  },
});
