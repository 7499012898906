
import { computed, defineComponent, ref, onMounted, getCurrentInstance, watchEffect, watch } from 'vue'
import PolicyService from "@/core/services/car/PolicyService";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { notif } from "@/store/stateless/store";

export default defineComponent({

  props: {
    isViewRefund: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, context) {
    const store = useStore();
    let isFormValid = ref<boolean>(true);
    let editRefundPolicyBodyParam = ref({
      policy_id: null,
      refund_request_id: 0,
      refund_payable_amount: 0,
      refund_status: null,
      pay_to: null,
      policy_status: 1,
      refund_account_notes: "",
      refund_type: null,
      refund_reasons: null,
      invoice_id: 0,
    });
    let bodyParameters = ref({
      status: null,
      per_page: "50",
      keyword: "",
      start_date: "",
      end_date: "",
      agent: "all",
      policy_sales_agent: "",
      underwriter: "",
      payment_type: "",
      refund_status: 1,
      cancellation_status: null,
    });

    let policyDetails = computed(() => {
      return store.getters.getpolicyDetails;
    });

    const refundModal = computed(() => {
      return store.getters.getRefundModal;
    });

    const getPolicyList = (bodyParameters) => {
      // bodyParameters.status =
      //   bodyParameters.status == 0 ? "" : bodyParameters.status;

      Promise.all([
        PolicyService.getRefundPolicyList(bodyParameters.value),
      ]).then((data) => {
        console.log(data);
      });
    };

    const reqRefundRules = ref({
      refund_type: {
        required: true,
        message: "Please select refund type",
        show: false,
      },
      refund_status: {
        required: true,
        message: "Please select refund status",
        show: false,
      },
      payable_amount: {
        required: true,
        message: "Please enter payable amount",
        show: false,
      },
      refund_account_notes: {
        required: true,
        message: "lease enter account notes",
        show: false,
      },
      refund_pay_to: {
        required: true,
        message: "Please select pay to",
        show: false,
      },
      policy_status: {
        required: true,
        message: "Please select status",
        show: false,
      },
      policy_notes: {
        required: true,
        message: "Please enter account notes",
        show: false,
      },
    });

    const validateRefundForm = () => {
      // console.log(editRefundPolicyBodyParam.value);
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (editRefundPolicyBodyParam.value.refund_type == "") {
        reqRefundRules.value.refund_type.show = true;
        isFormValid.value = false;
      }
      if (!decimalPlaces.test(String(editRefundPolicyBodyParam.value.refund_payable_amount))) {
        reqRefundRules.value.payable_amount.message = 'Please enter upto 2 decimal places only';
        reqRefundRules.value.payable_amount.show = true;
        isFormValid.value = false;
        isDecimalValid.value = false
      }

      if (editRefundPolicyBodyParam.value.refund_status == "") {
        reqRefundRules.value.refund_status.show = true;
        isFormValid.value = false;
      }
      if (
        editRefundPolicyBodyParam.value.refund_payable_amount == 0 &&
        editRefundPolicyBodyParam.value.refund_payable_amount == 0 && editRefundPolicyBodyParam.value.refund_status != "4"
      ) {
        reqRefundRules.value.payable_amount.show = true;
        isFormValid.value = false;
      }
      if (editRefundPolicyBodyParam.value.refund_account_notes == "") {
        reqRefundRules.value.refund_account_notes.show = true;
        isFormValid.value = false;
      }
      if (editRefundPolicyBodyParam.value.pay_to == "0" && editRefundPolicyBodyParam.value.refund_status != "4") {
        reqRefundRules.value.refund_pay_to.show = true;
        isFormValid.value = false;
      }
      if (editRefundPolicyBodyParam.value.refund_status == "1") {
        reqRefundRules.value.policy_status.show = true;
        isFormValid.value = false;
      }
      if (!editRefundPolicyBodyParam.value.refund_account_notes) {
        reqRefundRules.value.policy_notes.show = true;
        isFormValid.value = false;
      }
      // if(refundCategory.value === 2 && parseFloat(refundInvoice.value.invoice.amount) !== editRefundPolicyBodyParam.value.refund_payable_amount && policyDetails.value.car_refund_request.refund_status != 4) {
      if (refundCategory.value === 2 && parseFloat(refundInvoice.value.invoice.amount) !== parseFloat(editRefundPolicyBodyParam.value.refund_payable_amount.toString()) && policyDetails.value.car_refund_request.refund_status != 4) {
        // alert(parseFloat(policyDetails.value.car_refund_request?.invoice?.sale_price))
        reqRefundRules.value.payable_amount.show = true;
        reqRefundRules.value.payable_amount.message = 'Invoice amount must be equal to payable amount';
        isFormValid.value = false;
      }
    };

    onMounted(() => {
      if(policyDetails.value.car_refund_request.refund_type == 'full-refund') {
        editRefundPolicyBodyParam.value.policy_status = 1
      } else {
        editRefundPolicyBodyParam.value.policy_status = 2
      }
      PolicyService.resetPolicyError();
    })

    const cancellationReason = computed(() => {
      return store.getters.getCancellationReasons;
    });

    const onChangeRefundStatus = (event) => {
      reqRefundRules.value.policy_status.show = false;
      editRefundPolicyBodyParam.value.refund_status = event;
    };
    const onChangePayTo = (event) => {
      reqRefundRules.value.refund_pay_to.show = false;
      editRefundPolicyBodyParam.value.pay_to = event;
    };

    const resetRules = () => {
      reqRefundRules.value.refund_type.show = false;
      reqRefundRules.value.refund_status.show = false;
      reqRefundRules.value.payable_amount.show = false;
      reqRefundRules.value.refund_account_notes.show = false;
      reqRefundRules.value.policy_status.show = false;
      isDecimalValid.value = true;
    }

    const submitPolicyRefund = (policyObj) => {
      isDecimalValid.value = true
      PolicyService.resetPolicyError();
      resetRules();
      editRefundPolicyBodyParam.value.policy_id = policyObj.id;
      editRefundPolicyBodyParam.value.refund_payable_amount =
        policyObj.car_refund_request?.refund_payable_amount_agent == 0
          ? policyObj.car_refund_request?.refund_payable_amount_customer
          : policyObj.car_refund_request?.refund_payable_amount_agent;
      editRefundPolicyBodyParam.value.refund_account_notes = policyObj.car_refund_request?.refund_account_note;
      editRefundPolicyBodyParam.value.refund_type = policyObj?.car_refund_request?.refund_type;
      editRefundPolicyBodyParam.value.refund_request_id = policyObj?.car_refund_request?.id;

      if (editRefundPolicyBodyParam.value.refund_status == null) {
        editRefundPolicyBodyParam.value.refund_status =
          policyObj.car_refund_request.refund_status;
      }

      if (editRefundPolicyBodyParam.value.pay_to == null) {
        editRefundPolicyBodyParam.value.pay_to =
          policyObj.car_refund_request?.pay_to;
      }
      editRefundPolicyBodyParam.value.refund_reasons = refundCategory.value === 2 ? refundInvoice.value.refund_reasons : policyObj.car_refund_request.refund_reasons;
      if (refundCategory.value === 2) {
        editRefundPolicyBodyParam.value.invoice_id = refundInvoice.value.invoice_id;
      }
      editRefundPolicyBodyParam.value.policy_status = policyObj?.car_refund_request?.policy_status;

      isFormValid.value = true;
      validateRefundForm();
      if (isFormValid.value) {
        Promise.all([
          PolicyService.refundPolicyApproval(editRefundPolicyBodyParam.value),
        ]).then((data) => {

          if (!data[0].data.success && data[0].data.success != undefined) {
            let message = store.getters.getPolicyErrorDetails;
            ElMessage.error(message[0]);
          } else {
            // ElMessage({
            //   customClass: "notification-success",
            //   message: data[0].message,
            //   type: "success",
            // });
            if (!data[0].data.success && data[0].data.success != undefined) {
              // ElMessage.error(message[0]);
              // notif.simple(`Policy Refund`, "error", message[0]);
              // console.log(message);
              // context.emit("getRefund");

            } else {
              notif.simple(`Policy Refund`, "success", data[0].message);
              close();
              context.emit("getPolicyRefund")
              // context.emit("getRefund");

            }
            // getPolicyList(bodyParameters);
          }
        });
      } else {
        focusField()
      }
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const policyError = computed(() => {
      return store.getters.getPolicyErrors;
    })

    const totalRefundPayableAmountCustomer = computed(() => {
      const data = store.getters.getpolicyDetails
      if (data.car_refund_completed) {
        return data.car_refund_completed.reduce((total: any, item: any) => {
          return total += parseFloat(item.refund_payable_amount_customer)
        }, 0)
      } else {
        return 0;
      }
    })

    const totalRefundPayableAmountAgent = computed(() => {
      const data = store.getters.getpolicyDetails
      if (data.car_refund_completed) {
        return data.car_refund_completed.reduce((total: any, item: any) => {
          return total += parseFloat(item.refund_payable_amount_agent)
        }, 0)
      } else {
        return 0;
      }
    })

    const totalRefund = computed(() => {
      return parseFloat(totalRefundPayableAmountCustomer.value) + parseFloat(totalRefundPayableAmountAgent.value)
    })

    const close = () => {
      resetRules();
      PolicyService.setRefundModal(false);
      PolicyService.resetPolicyError();
      PolicyService.resetPolicyRefundError();
    }

    const refundError = computed(() => {
      return store.getters.getEditPolicyRefundError;
    })

    const resetPayableAmount = () => {
      reqRefundRules.value.payable_amount.show = false;
      isDecimalValid.value = true;
      PolicyService.resetPolicyRefundError();
    }

    const updatePolicyStatusOnRefundTypeChange = (event) => {
      const selectedValue = event.target.value;
      if(selectedValue == 'full-refund') {
        editRefundPolicyBodyParam.value.policy_status = 1;
      } else {
        editRefundPolicyBodyParam.value.policy_status = 2;
      }
    }

    watch(() => policyDetails.value.car_refund_request.refund_type, newType => {
      if(newType == 'full-refund') {
        policyDetails.value.car_refund_request.policy_status = 1
      } else {
        policyDetails.value.car_refund_request.policy_status = 2

      }
    })

    const isDecimalValid = ref(true);

    const getTextWidth = (text, font) => {
      const canvas = document.createElement('canvas');
      const context: any = canvas.getContext('2d');

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    }
    const payToList = computed(() => {
      const data = store.getters.getPayToList;
      if (!props.isViewRefund) {
        if (refundCategory.value == 1) {
          if (policyDetails.value.transaction_type !== 1) {
            return data.filter(x => x.value !== 1)
          }
        }
      }

      return data
    });

    const refundReasonList = computed(() => {
      return store.getters.getRefundReasons;
    });

    const refundReason = ref({
      placeholder: "Please Select Refund Reason",
      value: null,
      options: refundReasonList,
      searchable: true,
      clearOnSelect: true,
    });

    const inst = getCurrentInstance()
    const focusField = () => {
      const { payable_amount, refund_pay_to, policy_status, policy_notes } = reqRefundRules.value

      if (payable_amount.show) {
        const payableAmountRef: any = inst?.refs?.payableAmountRef
        payableAmountRef.isFocus()
      }

      if (!payable_amount.show && refund_pay_to.show) {
        const payToRef: any = inst?.refs?.payToRef
        payToRef.focus()
      }

      if (!payable_amount.show && !refund_pay_to.show && policy_status.show) {
        const statusRef: any = inst?.refs?.statusRef
        statusRef.focus()
      }

      if (!payable_amount.show && !refund_pay_to.show && !policy_status.show && policy_notes.show) {
        const notesRef: any = inst?.refs?.notesRef
        notesRef.focus()
      }
    }

    const toFirst = () => {
      setTimeout(() => {
        const refundTypeRef: any = inst?.refs?.refundTypeRef
        refundTypeRef.focus()
      }, 1000);
    }

    watchEffect(() => {
      toFirst()
    })

    const refundCategory = computed(() => {
      return store.getters.getCategoryRefund
    })

    const refundInvoice = computed(() => {
      const data = store.getters.getRefundInvoiceId
      if (data && data > 0 && policyDetails.value && policyDetails.value.car_refund_requests) return policyDetails.value.car_refund_requests.find(x => x.invoice_id === data)

      return {}
    })

    return {
      refundInvoice,
      refundCategory,
      toFirst,
      focusField,
      refundReason,
      refundReasonList,
      payToList,
      getTextWidth,
      resetPayableAmount,
      refundError,
      isDecimalValid,
      policyDetails,
      totalRefund,
      disableSaveButton,
      policyError,
      submitPolicyRefund,
      onChangeRefundStatus,
      onChangePayTo,
      cancellationReason,
      refundModal,
      isFormValid,
      editRefundPolicyBodyParam,
      bodyParameters,
      close,
      getPolicyList,
      reqRefundRules,
      validateRefundForm,
      updatePolicyStatusOnRefundTypeChange
    }
  },
})
