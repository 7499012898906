
import { computed, defineComponent, getCurrentInstance, ref, onBeforeMount } from "vue";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { useRoute, useRouter } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import { notif } from "@/store/stateless/store";


export default defineComponent({
  name: "transafer-sale-document-modal",
  components: { Multiselect },
  props: {
    policyId: Number,
  },
  setup(props,context) {
    const store = useStore();
    const saleTransferModalRef = ref<null | HTMLElement>(null);
    let salesAgentList = computed(() => {
      return store.getters.getActiveSalesAgent;
    });
    const salesAgent = ref({
      placeholder: "Please Select Agent",
      value: null,
      options: salesAgentList,
      searchable: true,
      clearOnSelect: true,
    });

    const isError = ref<boolean>(false);
    const onSelectSalesAgentRef = ref();
    const transferSale = (policy_id) => {
      // console.log(salesAgent.value.value);
      isError.value = false;

      if (salesAgent.value.value != null) {
        Promise.all([
          PolicyService.transferSale({
            policy_id: policy_id,
            agent: salesAgent.value.value,
          }),
        ]).then((data) => {
          salesAgent.value.value = null;
          onSelectSalesAgentRef.value.clear();
          context.emit('reset_policy_data')
          hideModal(saleTransferModalRef.value);
          notif.simple("Policy Transfer", "success", "You have successfully transfer the policy");
        });
      } else {
        isError.value = true;
      }
    };
    const disableTransferSale = computed(() => {
      return store.getters.getDisableTransferSale;
    });

    const salesTransferModal = () => {
      isError.value = false;
      salesAgent.value.value = null;
      onSelectSalesAgentRef.value.clear();
    }

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        const onSelectSalesAgentRef:any = inst?.refs?.onSelectSalesAgentRef
        onSelectSalesAgentRef.focusSearch()
      }, 500);
    }

    const cancelButton =ref()
    const closeModal = (e) => {
      if(e.which === 27) {
        cancelButton.value.click()
      }
    }

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      toFirst,
      cancelButton,
      onSelectSalesAgentRef,
      salesTransferModal,
      isError,
      salesAgentList,
      salesAgent,
      transferSale,
      disableTransferSale,
      saleTransferModalRef,
    };
  },
});
