
import { defineComponent,onMounted} from "vue";
import { useStore } from "vuex";
import ListPolicy from "@/components/partials/policy/List-Policy.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { roles } from "@/store/stateless/store";

export default defineComponent({
  name: "policy-cancelled",
  components: {
    ListPolicy,

    //   AIExplore
  },
  data() {
    return {

      status: [7,11],
      type: "cancellation_completed",
      isqca: true,

    };
  },
  setup() {
    const store = useStore();
      onMounted(() => {
      if(!roles.policiesCancelled.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Policies Cancelled", ["Policy"]);

    });
    return {
      roles
    };
  },
});
