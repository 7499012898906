
import { computed, defineComponent, ref, onMounted, watchEffect, getCurrentInstance } from "vue";

import { useStore } from "vuex";
import PolicyService from "@/core/services/car/PolicyService";
import { onBeforeRouteLeave } from "vue-router";
import { ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
// import DropzoneFile from "@/views/car/policy/DropZone.vue";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
import { notif } from "@/store/stateless/store";
import moment from 'moment';

interface editCancelPolicyBodyParam {
  policy_id: any | null;
  cancellation_payable_amount: any | null;
  cancellation_debit_amount: any | null;
  cancellation_debit_notes_no: any | null;
  cancellation_credit_amount: any | null;
  cancellation_credit_notes_no: any | null;
  cancellation_endorsement_no: any | null;
  cancellation_status: any | null;
  cancellation_date: any | null;
  pay_to: any | null;
  cancellation_type: any | null;
  account_notes: any | null;
  quote_ref_number: any | null;
}
interface PolicyBodyParam {
  per_page: string;
  status: any;
  keyword: string;
  start_date: string | null;
  end_date: string | null;
  agent: string;
  policy_sales_agent: any;
  underwriter: any;
  payment_type: any;
}
export default defineComponent({
  name: "edit-cancel-approval-document-modal",
  components: {
    // DropzoneFile,
    ViewTempDocuments,
  },
  props: {
    policyId: Number,
    policyObj: { type: Object, required: true },
    cancellationDocs: Array,
    status: Number,
    tempDocs: Array,
    isViewCancellation: {
      type: Boolean,
      required: false,
      default: false,
    },
    
  },
  setup(props, context) {
    const store = useStore();
    const editCancelPolicyReqModalRef = ref<null | HTMLElement>(null);
    let isFormValid = ref<boolean>(true);

    let editCancelPolicyBodyParam = ref<editCancelPolicyBodyParam>({
      policy_id: null,
      cancellation_payable_amount: null,
      cancellation_debit_amount: null,
      cancellation_debit_notes_no: null,
      cancellation_credit_amount: null,
      cancellation_credit_notes_no: null,
      cancellation_endorsement_no: null,
      cancellation_status: null,
      cancellation_date: null,
      account_notes: "",
      cancellation_type: null,
      pay_to: -1,
      quote_ref_number: "",
    });
    let bodyParameters = ref<PolicyBodyParam>({
      status: props.status,
      per_page: "50",
      keyword: "",
      start_date: "",
      end_date: "",
      agent: "all",
      policy_sales_agent: "",
      underwriter: "",
      payment_type: "",
    });
    let policyDetails = computed(() => {
      return store.getters.getpolicyDetails;
    });

    const defaultCancellation = ref(6);
    const defaValue = () => {
      if(policyDetails.value) {
        defaultCancellation.value = policyDetails.value.cancellation_status_id;
      }
    };    
  
    const approveCancellationRules = ref({
      cancellation_payable_amount: {
        message: "Please enter payable amount",
        show: false,
      },
      cancellation_type: {
        required: true,
        message: "Please select the cancellation type",
        show: false,
      },
      cancellation_status: {
        message: "Please select status",
        show: false,
      },
      cancellation_debit_amount: {
        message: "Debit note",
        show: false,
      },
      cancellation_debit_notes_no: {
        message: "Debit note no.",
        show: false,
      },
      cancellation_credit_amount: {
        message: "Credit note",
        show: false,
      },
      cancellation_credit_notes_no: {
        message: "Credit note no.",
        show: false,
      },
      cancellation_endorsement_no: {
        message: "Endorsement no.",
        show: false,
      },
      pay_to: {
        message: "Please select pay to",
        show: false,
      },
      cancellation_date: {
        message: "Please enter the cancellation date on invoice",
        show: false,
      },
      cancellation_debit_amount_error: {
        message: "Incorrect amount",
        show: false,
      },
    });

    const debitDecimalValid = ref(true);
    const creditDecimalValid = ref(true);
    const validateCancellationForm = () => {
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;

      approveCancellationRules.value.cancellation_payable_amount.message = "Please enter payable amount"
      
      if (parseFloat(editCancelPolicyBodyParam.value.cancellation_payable_amount) <= 0 &&
      policyDetails.value.cancellation_type != "no-reimbursement"
      ) {
        approveCancellationRules.value.cancellation_payable_amount.show = true;
        isFormValid.value = false;
      }

      if(parseFloat(editCancelPolicyBodyParam.value.cancellation_payable_amount) > parseFloat(policyDetails.value.car_lead_invoice.sale_price)) {
        approveCancellationRules.value.cancellation_payable_amount.show = true;
        approveCancellationRules.value.cancellation_payable_amount.message =
          "Payable amount cannot be greater than invoice amount.";
        isFormValid.value = false;
      }

      if (!editCancelPolicyBodyParam.value.cancellation_status || 
        editCancelPolicyBodyParam.value.cancellation_status == '6' ||
        editCancelPolicyBodyParam.value.cancellation_status == '') {
        approveCancellationRules.value.cancellation_status.show = true;
        isFormValid.value = false;
      }
      if(!['9', '10'].includes(editCancelPolicyBodyParam.value.cancellation_status))
      {
         if (
          !editCancelPolicyBodyParam.value.pay_to ||
          editCancelPolicyBodyParam.value.pay_to == "0" ||
          editCancelPolicyBodyParam.value.pay_to == "-1"
        ) {
          approveCancellationRules.value.pay_to.show = true;
          isFormValid.value = false;
        }
      }

      // if(policyDetails.value.cancellation_type !== 'no-reimbursement') {
      //   if(!policyDetails.value.cancellation_credit_notes_no) {
      //     approveCancellationRules.value.cancellation_credit_notes_no.show = true
      //     isFormValid.value = false;
      //   }
      //   if(!policyDetails.value.cancellation_debit_notes_no) {
      //     approveCancellationRules.value.cancellation_debit_notes_no.show = true
      //     isFormValid.value = false;
      //   }
      // }
     
      if (
        editCancelPolicyBodyParam.value.cancellation_type == "" ||
        editCancelPolicyBodyParam.value.cancellation_type == null
      ) {
        approveCancellationRules.value.cancellation_type.show = true;
        isFormValid.value = false;
      }
      // if(!decimalPlaces.test(String(editCancelPolicyBodyParam.value.cancellation_credit_amount))) {
      //   approveCancellationRules.value.cancellation_credit_amount.message = 'Please enter upto 2 decimal places only';
      //   approveCancellationRules.value.cancellation_credit_amount.show = true;
      //   isFormValid.value = false;
      //   creditDecimalValid.value = false
      // }
       if(!decimalPlaces.test(String(editCancelPolicyBodyParam.value.cancellation_debit_amount))) {
        approveCancellationRules.value.cancellation_debit_amount.message = 'Please enter upto 2 decimal places only';
        approveCancellationRules.value.cancellation_debit_amount.show = true;
        isFormValid.value = false;
        debitDecimalValid.value = false
      }

      if(editCancelPolicyBodyParam.value.cancellation_debit_amount >= 1000000000) {
        approveCancellationRules.value.cancellation_debit_amount_error.show = true;
        isFormValid.value = false;
        // debitDecimalValid.value = false
      }

      if (!editCancelPolicyBodyParam.value.cancellation_date) {
        approveCancellationRules.value.cancellation_date.show = true;
        isFormValid.value = false;
      }
    };
    watchEffect(() => {
      // policyDetails.value.cancellation_payable_amount = ;

      // if(policyDetails.value.cancellation_payable_amount != policyDetails.value.cancellation_credit_amount) {
      //   roundUp(policyDetails.value.cancellation_payable_amount);
      // }
    })

    const payableAmount = () => {
      if(policyDetails.value.cancellation_type === 'partial-cancellation') {
        policyDetails.value.cancellation_payable_amount = roundUp(policyDetails.value.cancellation_credit_amount - policyDetails.value.cancellation_debit_amount);
      } else {
        policyDetails.value.cancellation_payable_amount = roundUp(policyDetails.value.cancellation_credit_amount)
      }
    }

    const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const calculatePayable = () => {
      // console.log(policyDetails);
      creditDecimalValid.value = true;
      approveCancellationRules.value.cancellation_payable_amount.show = false;
      // policyDetails.value.cancellation_payable_amount =
      //   policyDetails.value.cancellation_credit_amount -
      //   policyDetails.value.cancellation_debit_amount;
    };
    const cancellationReason = computed(() => {
      return store.getters.getCancellationReasons;
    });
    const submitPolicyCancellation = (policyObj) => {
      isFormValid.value = true;

      resetRules()

      editCancelPolicyBodyParam.value.cancellation_type = policyObj.cancellation_type;
      editCancelPolicyBodyParam.value.policy_id = policyObj.id;
      editCancelPolicyBodyParam.value.cancellation_payable_amount = policyDetails.value.cancellation_payable_amount;
      editCancelPolicyBodyParam.value.cancellation_debit_amount = policyDetails.value.cancellation_debit_amount;
      editCancelPolicyBodyParam.value.cancellation_debit_notes_no = policyDetails.value.cancellation_debit_notes_no;
      editCancelPolicyBodyParam.value.cancellation_credit_amount = policyDetails.value.cancellation_credit_amount;
      editCancelPolicyBodyParam.value.cancellation_credit_notes_no = policyDetails.value.cancellation_credit_notes_no;
      editCancelPolicyBodyParam.value.cancellation_endorsement_no = policyDetails.value.cancellation_endorsement_no;
      editCancelPolicyBodyParam.value.cancellation_status = policyDetails.value.cancellation_status_id;
      editCancelPolicyBodyParam.value.cancellation_date = policyDetails.value.cancellation_date;
      editCancelPolicyBodyParam.value.pay_to = policyDetails.value.pay_to;
      editCancelPolicyBodyParam.value.account_notes = policyObj.account_notes;
      editCancelPolicyBodyParam.value.quote_ref_number = policyObj.car_lead_quote?.quote_ref_no;

      validateCancellationForm();
      if (isFormValid.value) {
        Promise.all([
          PolicyService.cancelPolicyApproval(editCancelPolicyBodyParam.value),
        ]).then((data) => {
          close();
          if (!data[0].data.success && data[0].data.success != undefined) {
            // let message = store.getters.getPolicyErrorDetails;
            // notif.simple("Policy Cancellation", "error", message[0]);
          } else {
            notif.simple("Policy Cancellation", "success", data[0].message);
            context.emit("getPolicyCancel")
          }
        });
      } else {
        focusField()
      }
    };
    const showDomLoad = computed(() => {
      return store.getters.getPolicyDocumentDomStatus;
    });
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    const policyCancellationDocuments = computed(() => {
      return store.getters.getPolicyCancellationDocuments;
    });

    const currentIndex = ref();
    const openSasame = (e) => {
      currentIndex.value = e;
    };

    const defaultValue = ref<number>(0);
    const cancellationType = (e) => {
      // if(e == 'full') {
      //   defaultValue.value = policyDetails.value.cancellation_debit_amount;
      //   policyDetails.value.cancellation_debit_amount = 0
      // } else {
      //   policyDetails.value.cancellation_debit_amount = defaultValue.value
      // }
      payableAmount()
    }

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    })

    const formatDate = (e) => {
      return moment(e).format('DD/MM/YYYY');
    }

    const getTextWidth = (text, font) => {
      const canvas = document.createElement('canvas');
      const context: any = canvas.getContext('2d');

      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    }
    const payToList = computed(() => {
      return store.getters.getPayToList;
    });

    const inst = getCurrentInstance()

    const focusField = () => {
      const { cancellation_credit_amount, cancellation_payable_amount, cancellation_credit_notes_no, cancellation_debit_amount, cancellation_debit_notes_no, cancellation_endorsement_no, pay_to, cancellation_status } = approveCancellationRules.value

      if(cancellation_credit_amount.show || cancellation_payable_amount.show) {
        const cnRef:any = inst?.refs?.cnRef
        cnRef.isFocus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && cancellation_credit_notes_no.show) {
        const cnNoRef:any = inst?.refs?.cnNoRef
        cnNoRef.focus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && cancellation_debit_amount.show) {
        const dnRef:any = inst?.refs?.dnRef
        dnRef.isFocus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && !cancellation_debit_amount.show && cancellation_debit_notes_no.show) {
        const dnNoRef:any = inst?.refs?.dnNoRef
        dnNoRef.focus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && !cancellation_debit_amount.show && !cancellation_debit_notes_no.show && cancellation_endorsement_no.show) {
        const endorsementRef:any = inst?.refs?.endorsementRef
        endorsementRef.focus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && !cancellation_debit_amount.show && !cancellation_debit_notes_no.show && !cancellation_endorsement_no.show && pay_to.show) {
        const payToRef:any = inst?.refs?.payToRef
        payToRef.focus()
      }

      if(!cancellation_credit_amount.show && !cancellation_payable_amount.show && !cancellation_credit_notes_no.show && !cancellation_debit_amount.show && !cancellation_debit_notes_no.show && !cancellation_endorsement_no.show && !pay_to.show && cancellation_status.show) {
        const statusRef:any = inst?.refs?.statusRef
        statusRef.focus()
      }
    }

    const setFocuToPayTo = () => {
      const payToRef:any = inst?.refs?.payToRef
      payToRef.focus()
    }

    const toFirst = () => {
      setTimeout(() => {
        const cancellationTypeRef:any = inst?.refs?.cancellationTypeRef
        cancellationTypeRef.focus()
      }, 1000);
    }

    const cancelModal = computed(() => {
      return store.getters.getCancelModal;
    });

    const close = () => {
      resetRules()
      PolicyService.setCancelModal(false);
      PolicyService.resetPolicyDetails({});
      PolicyService.resetPolicyError();
    }

    const resetRules = () => {
      approveCancellationRules.value.cancellation_payable_amount.show = false;
      approveCancellationRules.value.cancellation_type.show = false;
      approveCancellationRules.value.cancellation_status.show = false;
      approveCancellationRules.value.cancellation_debit_amount.show = false;
      approveCancellationRules.value.cancellation_debit_notes_no.show = false;
      approveCancellationRules.value.cancellation_credit_amount.show = false;
      approveCancellationRules.value.cancellation_credit_notes_no.show = false;
      approveCancellationRules.value.cancellation_endorsement_no.show = false;
      approveCancellationRules.value.cancellation_date.show = false;
      approveCancellationRules.value.pay_to.show = false;

      debitDecimalValid.value = true;
      creditDecimalValid.value = true;
    }

    return {
      defaValue,
      defaultCancellation,
      setFocuToPayTo,
      close,
      cancelModal,
      toFirst,
      focusField,
      payableAmount,
      payToList,
      getTextWidth,
      formatDate,
      isFormValid,
      ElNotification,
      defaultValue,
      cancellationType,
      currentIndex,
      openSasame,
      policyCancellationDocuments,
      editCancelPolicyReqModalRef,
      policyDetails,
      editCancelPolicyBodyParam,
      cancellationReason,
      calculatePayable,
      submitPolicyCancellation,
      approveCancellationRules,
      showDomLoad,
      disableSaveButton,
      creditDecimalValid,
      debitDecimalValid,
    };
  },
});
